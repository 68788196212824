export default {
    "items": [
        {
            "id": 1,
            "title": "1 Тест геоданных",
            "type": {
                "code": "own",
                "title": "Собственная"
            },
            "status": {
                "code": "active",
                "title": "Работает"
            },
            "address": {
                "country": "Россия",
                "region": "Москва",
                "city": "г Москва",
                "postcode": "119311",
                "street": "Строителей",
                "building": "2/72"
            },
            "isNew": false,
            "activity": true,
            "completeness": 10,
            "createdAt": "2024-05-16T10:57:44+03:00",
            "editedAt": "2024-12-19T08:27:55+03:00",
            "adplatforms": [
                {
                    "id": 1000003,
                    "title": "Геодата Яндекс",
                    "type": "yandex",
                    "url": "https://yandex.ru/maps/16532849",
                    "rating": 3.3,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 7,
                        "patch": [
                            {
                                "code": "coordinates@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68529,
                                    "longitude": 37.538817
                                }
                            },
                            {
                                "code": "emails@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Адреса электронной почты",
                                "value": [
                                    "clients@intelliauto.ru"
                                ]
                            },
                            {
                                "code": "ok_link@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru/22231"
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (915) 005-00-00",
                                        "comment": "Отделх закупок"
                                    }
                                ]
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "tg_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "twitter_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "websites@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылки на сайты",
                                "value": [
                                    "https://intelliauto.ru/",
                                    "https://intelliauto.ru/clietns"
                                ]
                            },
                            {
                                "code": "whatsapp_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "11:00",
                                            "finish": "20:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T05:36:06+03:00",
                        "state": {
                            "code": "error",
                            "name": "Ошибка"
                        }
                    }
                },
                {
                    "id": 1000011,
                    "title": "Геодата 2ГИС",
                    "type": "2gis",
                    "url": "https://account.2gis.com/orgs/70000001067239017/reviews/70000001067239018",
                    "rating": 4.1,
                    "completeness": 50,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 3,
                        "patch": [
                            {
                                "code": "category@2gis",
                                "direction": "incomming",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "431",
                                        "title": "Продажа легковых автомобилей",
                                        "primary": true
                                    },
                                    {
                                        "id": "112787",
                                        "title": "Автоинструкторы",
                                        "primary": false
                                    }
                                ]
                            },
                            {
                                "code": "category@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "425",
                                        "title": "Автоаксессуары",
                                        "primary": true
                                    }
                                ]
                            },
                            {
                                "code": "coordinates@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68498252166558,
                                    "longitude": 37.53930602648922
                                }
                            },
                            {
                                "code": "ok_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru"
                            },
                            {
                                "code": "phones@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "short_title@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Краткое название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "tg_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@2gis",
                                "direction": "incomming",
                                "action": "change",
                                "source": "2gis",
                                "title": "Название на русском языке",
                                "value": "Интелли Авто, компания по выкупу автомобилей"
                            },
                            {
                                "code": "twitter_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "whatsapp_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 5,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T03:16:00+03:00",
                        "sentAt": "2024-12-09T08:10:13+03:00",
                        "state": {
                            "code": "sent",
                            "name": "Отправлено"
                        }
                    }
                }
            ]
        },
        {
            "id": 2,
            "title": "2 Тест геоданных",
            "type": {
                "code": "franchise",
                "title": "Франшиза"
            },
            "status": {
                "code": "temp_not_working",
                "title": "Временно не работает"
            },
            "address": {
                "country": "Россия",
                "region": "Москва",
                "city": "г Москва",
                "postcode": "119311",
                "street": "Строителей",
                "building": "2/72"
            },
            "isNew": false,
            "activity": true,
            "completeness": 50,
            "createdAt": "2024-05-16T10:57:44+03:00",
            "editedAt": "2024-12-19T08:27:55+03:00",
            "adplatforms": [
                {
                    "id": 1000003,
                    "title": "Геодата Яндекс",
                    "type": "yandex",
                    "url": "https://yandex.ru/maps/16532849",
                    "rating": 4.5,
                    "completeness": 10,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 0,
                        "patch": [
                            {
                                "code": "coordinates@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68529,
                                    "longitude": 37.538817
                                }
                            },
                            {
                                "code": "emails@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Адреса электронной почты",
                                "value": [
                                    "clients@intelliauto.ru"
                                ]
                            },
                            {
                                "code": "ok_link@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru/22231"
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (915) 005-00-00",
                                        "comment": "Отделх закупок"
                                    }
                                ]
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "tg_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "twitter_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "websites@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылки на сайты",
                                "value": [
                                    "https://intelliauto.ru/",
                                    "https://intelliauto.ru/clietns"
                                ]
                            },
                            {
                                "code": "whatsapp_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "11:00",
                                            "finish": "20:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T05:36:06+03:00",
                        "state": {
                            "code": "error",
                            "name": "Ошибка"
                        }
                    }
                },
                {
                    "id": 1000011,
                    "title": "Геодата 2ГИС",
                    "type": "2gis",
                    "url": "https://account.2gis.com/orgs/70000001067239017/reviews/70000001067239018",
                    "rating": 1.1,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "unknown",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 3,
                        "patch": [
                            {
                                "code": "category@2gis",
                                "direction": "incomming",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "431",
                                        "title": "Продажа легковых автомобилей",
                                        "primary": true
                                    },
                                    {
                                        "id": "112787",
                                        "title": "Автоинструкторы",
                                        "primary": false
                                    }
                                ]
                            },
                            {
                                "code": "category@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "425",
                                        "title": "Автоаксессуары",
                                        "primary": true
                                    }
                                ]
                            },
                            {
                                "code": "coordinates@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68498252166558,
                                    "longitude": 37.53930602648922
                                }
                            },
                            {
                                "code": "ok_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru"
                            },
                            {
                                "code": "phones@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "short_title@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Краткое название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "tg_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@2gis",
                                "direction": "incomming",
                                "action": "change",
                                "source": "2gis",
                                "title": "Название на русском языке",
                                "value": "Интелли Авто, компания по выкупу автомобилей"
                            },
                            {
                                "code": "twitter_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "whatsapp_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 5,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T03:16:00+03:00",
                        "sentAt": "2024-12-09T08:10:13+03:00",
                        "state": {
                            "code": "sent",
                            "name": "Отправлено"
                        }
                    }
                }
            ]
        },        {
            "id": 3,
            "title": "3 Тест геоданных",
            "type": {
                "code": "own",
                "title": "Собственная"
            },
            "status": {
                "code": "no_longer_works",
                "title": "Больше не работает"
            },
            "address": {
                "country": "Россия",
                "region": "Москва",
                "city": "г Москва",
                "postcode": "119311",
                "street": "Строителей",
                "building": "2/72"
            },
            "isNew": false,
            "activity": true,
            "completeness": 60,
            "createdAt": "2024-05-16T10:57:44+03:00",
            "editedAt": "2024-12-19T08:27:55+03:00",
            "adplatforms": [
                {
                    "id": 1000003,
                    "title": "Геодата Яндекс",
                    "type": "yandex",
                    "url": "https://yandex.ru/maps/16532849",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 7,
                        "patch": [
                            {
                                "code": "coordinates@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68529,
                                    "longitude": 37.538817
                                }
                            },
                            {
                                "code": "emails@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Адреса электронной почты",
                                "value": [
                                    "clients@intelliauto.ru"
                                ]
                            },
                            {
                                "code": "ok_link@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru/22231"
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (915) 005-00-00",
                                        "comment": "Отделх закупок"
                                    }
                                ]
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "tg_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "twitter_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "websites@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылки на сайты",
                                "value": [
                                    "https://intelliauto.ru/",
                                    "https://intelliauto.ru/clietns"
                                ]
                            },
                            {
                                "code": "whatsapp_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "11:00",
                                            "finish": "20:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T05:36:06+03:00",
                        "state": {
                            "code": "error",
                            "name": "Ошибка"
                        }
                    }
                },
                {
                    "id": 1000011,
                    "title": "Геодата 2ГИС",
                    "type": "2gis",
                    "url": "https://account.2gis.com/orgs/70000001067239017/reviews/70000001067239018",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 3,
                        "patch": [
                            {
                                "code": "category@2gis",
                                "direction": "incomming",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "431",
                                        "title": "Продажа легковых автомобилей",
                                        "primary": true
                                    },
                                    {
                                        "id": "112787",
                                        "title": "Автоинструкторы",
                                        "primary": false
                                    }
                                ]
                            },
                            {
                                "code": "category@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "425",
                                        "title": "Автоаксессуары",
                                        "primary": true
                                    }
                                ]
                            },
                            {
                                "code": "coordinates@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68498252166558,
                                    "longitude": 37.53930602648922
                                }
                            },
                            {
                                "code": "ok_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru"
                            },
                            {
                                "code": "phones@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "short_title@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Краткое название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "tg_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@2gis",
                                "direction": "incomming",
                                "action": "change",
                                "source": "2gis",
                                "title": "Название на русском языке",
                                "value": "Интелли Авто, компания по выкупу автомобилей"
                            },
                            {
                                "code": "twitter_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "whatsapp_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 5,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T03:16:00+03:00",
                        "sentAt": "2024-12-09T08:10:13+03:00",
                        "state": {
                            "code": "sent",
                            "name": "Отправлено"
                        }
                    }
                }
            ]
        },        {
            "id": 4,
            "title": "4 Тест геоданных",
            "type": {
                "code": "own",
                "title": "Собственная"
            },
            "status": {
                "code": "active",
                "title": "Работает"
            },
            "address": {
                "country": "Россия",
                "region": "Москва",
                "city": "г Москва",
                "postcode": "119311",
                "street": "Строителей",
                "building": "2/72"
            },
            "isNew": false,
            "activity": true,
            "completeness": 20,
            "createdAt": "2024-05-16T10:57:44+03:00",
            "editedAt": "2024-12-19T08:27:55+03:00",
            "adplatforms": [
                {
                    "id": 1000003,
                    "title": "Геодата Яндекс",
                    "type": "yandex",
                    "url": "https://yandex.ru/maps/16532849",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 7,
                        "patch": [
                            {
                                "code": "coordinates@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68529,
                                    "longitude": 37.538817
                                }
                            },
                            {
                                "code": "emails@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Адреса электронной почты",
                                "value": [
                                    "clients@intelliauto.ru"
                                ]
                            },
                            {
                                "code": "ok_link@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru/22231"
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (915) 005-00-00",
                                        "comment": "Отделх закупок"
                                    }
                                ]
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "tg_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "twitter_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "websites@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылки на сайты",
                                "value": [
                                    "https://intelliauto.ru/",
                                    "https://intelliauto.ru/clietns"
                                ]
                            },
                            {
                                "code": "whatsapp_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "11:00",
                                            "finish": "20:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T05:36:06+03:00",
                        "state": {
                            "code": "error",
                            "name": "Ошибка"
                        }
                    }
                },
                {
                    "id": 1000011,
                    "title": "Геодата 2ГИС",
                    "type": "2gis",
                    "url": "https://account.2gis.com/orgs/70000001067239017/reviews/70000001067239018",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 3,
                        "patch": [
                            {
                                "code": "category@2gis",
                                "direction": "incomming",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "431",
                                        "title": "Продажа легковых автомобилей",
                                        "primary": true
                                    },
                                    {
                                        "id": "112787",
                                        "title": "Автоинструкторы",
                                        "primary": false
                                    }
                                ]
                            },
                            {
                                "code": "category@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "425",
                                        "title": "Автоаксессуары",
                                        "primary": true
                                    }
                                ]
                            },
                            {
                                "code": "coordinates@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68498252166558,
                                    "longitude": 37.53930602648922
                                }
                            },
                            {
                                "code": "ok_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru"
                            },
                            {
                                "code": "phones@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "short_title@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Краткое название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "tg_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@2gis",
                                "direction": "incomming",
                                "action": "change",
                                "source": "2gis",
                                "title": "Название на русском языке",
                                "value": "Интелли Авто, компания по выкупу автомобилей"
                            },
                            {
                                "code": "twitter_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "whatsapp_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 5,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T03:16:00+03:00",
                        "sentAt": "2024-12-09T08:10:13+03:00",
                        "state": {
                            "code": "sent",
                            "name": "Отправлено"
                        }
                    }
                }
            ]
        },        {
            "id": 5,
            "title": "5 Тест геоданных",
            "type": {
                "code": "own",
                "title": "Собственная"
            },
            "status": {
                "code": "active",
                "title": "Работает"
            },
            "address": {
                "country": "Россия",
                "region": "Москва",
                "city": "г Москва",
                "postcode": "119311",
                "street": "Строителей",
                "building": "2/72"
            },
            "isNew": false,
            "activity": true,
            "completeness": 80,
            "createdAt": "2024-05-16T10:57:44+03:00",
            "editedAt": "2024-12-19T08:27:55+03:00",
            "adplatforms": [
                {
                    "id": 1000003,
                    "title": "Геодата Яндекс",
                    "type": "yandex",
                    "url": "https://yandex.ru/maps/16532849",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 7,
                        "patch": [
                            {
                                "code": "coordinates@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68529,
                                    "longitude": 37.538817
                                }
                            },
                            {
                                "code": "emails@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Адреса электронной почты",
                                "value": [
                                    "clients@intelliauto.ru"
                                ]
                            },
                            {
                                "code": "ok_link@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru/22231"
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (915) 005-00-00",
                                        "comment": "Отделх закупок"
                                    }
                                ]
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "tg_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "twitter_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "websites@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылки на сайты",
                                "value": [
                                    "https://intelliauto.ru/",
                                    "https://intelliauto.ru/clietns"
                                ]
                            },
                            {
                                "code": "whatsapp_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "11:00",
                                            "finish": "20:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T05:36:06+03:00",
                        "state": {
                            "code": "error",
                            "name": "Ошибка"
                        }
                    }
                },
                {
                    "id": 1000011,
                    "title": "Геодата 2ГИС",
                    "type": "2gis",
                    "url": "https://account.2gis.com/orgs/70000001067239017/reviews/70000001067239018",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 3,
                        "patch": [
                            {
                                "code": "category@2gis",
                                "direction": "incomming",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "431",
                                        "title": "Продажа легковых автомобилей",
                                        "primary": true
                                    },
                                    {
                                        "id": "112787",
                                        "title": "Автоинструкторы",
                                        "primary": false
                                    }
                                ]
                            },
                            {
                                "code": "category@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "425",
                                        "title": "Автоаксессуары",
                                        "primary": true
                                    }
                                ]
                            },
                            {
                                "code": "coordinates@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68498252166558,
                                    "longitude": 37.53930602648922
                                }
                            },
                            {
                                "code": "ok_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru"
                            },
                            {
                                "code": "phones@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "short_title@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Краткое название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "tg_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@2gis",
                                "direction": "incomming",
                                "action": "change",
                                "source": "2gis",
                                "title": "Название на русском языке",
                                "value": "Интелли Авто, компания по выкупу автомобилей"
                            },
                            {
                                "code": "twitter_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "whatsapp_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 5,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T03:16:00+03:00",
                        "sentAt": "2024-12-09T08:10:13+03:00",
                        "state": {
                            "code": "sent",
                            "name": "Отправлено"
                        }
                    }
                }
            ]
        },        {
            "id": 6,
            "title": "6 Тест геоданных",
            "type": {
                "code": "own",
                "title": "Собственная"
            },
            "status": {
                "code": "active",
                "title": "Работает"
            },
            "address": {
                "country": "Россия",
                "region": "Москва",
                "city": "г Москва",
                "postcode": "119311",
                "street": "Строителей",
                "building": "2/72"
            },
            "isNew": false,
            "activity": true,
            "completeness": 95,
            "createdAt": "2024-05-16T10:57:44+03:00",
            "editedAt": "2024-12-19T08:27:55+03:00",
            "adplatforms": [
                {
                    "id": 1000003,
                    "title": "Геодата Яндекс",
                    "type": "yandex",
                    "url": "https://yandex.ru/maps/16532849",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 7,
                        "patch": [
                            {
                                "code": "coordinates@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68529,
                                    "longitude": 37.538817
                                }
                            },
                            {
                                "code": "emails@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Адреса электронной почты",
                                "value": [
                                    "clients@intelliauto.ru"
                                ]
                            },
                            {
                                "code": "ok_link@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru/22231"
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (915) 005-00-00",
                                        "comment": "Отделх закупок"
                                    }
                                ]
                            },
                            {
                                "code": "phones@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "tg_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "twitter_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "websites@yandex",
                                "direction": "incomming",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылки на сайты",
                                "value": [
                                    "https://intelliauto.ru/",
                                    "https://intelliauto.ru/clietns"
                                ]
                            },
                            {
                                "code": "whatsapp_phone@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@yandex",
                                "direction": "incomming",
                                "action": "change",
                                "source": "yandex",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "11:00",
                                            "finish": "20:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@yandex",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "yandex",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T05:36:06+03:00",
                        "state": {
                            "code": "error",
                            "name": "Ошибка"
                        }
                    }
                },
                {
                    "id": 1000011,
                    "title": "Геодата 2ГИС",
                    "type": "2gis",
                    "url": "https://account.2gis.com/orgs/70000001067239017/reviews/70000001067239018",
                    "rating": 0.0,
                    "completeness": 89,
                    "source": {
                        "state": {
                            "code": "enabled",
                            "name": "Включено"
                        },
                        "checkedAt": "2024-12-24T08:10:11+03:00"
                    },
                    "awards": [
                        {
                            "year": 2022,
                            "code": "goodplace",
                            "title": "Хорошее место"
                        }
                    ],
                    "badges": [
                        {
                            "code": "confirmed",
                            "title": "Владелец следит за актуальностью информации"
                        }
                    ],
                    "difference": {
                        "count": 3,
                        "patch": [
                            {
                                "code": "category@2gis",
                                "direction": "incomming",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "431",
                                        "title": "Продажа легковых автомобилей",
                                        "primary": true
                                    },
                                    {
                                        "id": "112787",
                                        "title": "Автоинструкторы",
                                        "primary": false
                                    }
                                ]
                            },
                            {
                                "code": "category@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Категория",
                                "value": [
                                    {
                                        "id": "425",
                                        "title": "Автоаксессуары",
                                        "primary": true
                                    }
                                ]
                            },
                            {
                                "code": "coordinates@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Геопозиция на карте",
                                "value": {
                                    "latitude": 55.68498252166558,
                                    "longitude": 37.53930602648922
                                }
                            },
                            {
                                "code": "ok_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Одноклассники",
                                "value": "http://ok.ru"
                            },
                            {
                                "code": "phones@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Телефоны",
                                "value": [
                                    {
                                        "number": "+7 (999) 999-99-99",
                                        "comment": "Отделх закупок"
                                    },
                                    {
                                        "number": "+7 (111) 111-11-11",
                                        "comment": "1111"
                                    }
                                ]
                            },
                            {
                                "code": "short_title@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Краткое название на русском языке",
                                "value": "Интелли-Авто"
                            },
                            {
                                "code": "tg_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Telegram",
                                "value": "http://tt.com"
                            },
                            {
                                "code": "title@2gis",
                                "direction": "incomming",
                                "action": "change",
                                "source": "2gis",
                                "title": "Название на русском языке",
                                "value": "Интелли Авто, компания по выкупу автомобилей"
                            },
                            {
                                "code": "twitter_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на Twitter",
                                "value": "http://twitter.ru"
                            },
                            {
                                "code": "viber_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Viber",
                                "value": "+7 (555) 555-55-55"
                            },
                            {
                                "code": "vk_url@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на VK",
                                "value": "http://vk.ru/sdfds"
                            },
                            {
                                "code": "whatsapp_phone@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Номер телефона в Whatsapp",
                                "value": "+7 (112) 312-31-13"
                            },
                            {
                                "code": "work-time@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Время работы",
                                "value": [
                                    {
                                        "day": 1,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 2,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 3,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 4,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    },
                                    {
                                        "day": 5,
                                        "work": {
                                            "start": "10:00",
                                            "finish": "19:00"
                                        }
                                    }
                                ]
                            },
                            {
                                "code": "youtube_link@2gis",
                                "direction": "outgoing",
                                "action": "add",
                                "source": "2gis",
                                "title": "Ссылка на YouTube",
                                "value": "http://youtube.com"
                            }
                        ]
                    },
                    "sync": {
                        "parsedAt": "2024-12-24T03:16:00+03:00",
                        "sentAt": "2024-12-09T08:10:13+03:00",
                        "state": {
                            "code": "sent",
                            "name": "Отправлено"
                        }
                    }
                }
            ]
        },
    ],
    "sorting": {
        "column": "title",
        "order": "asc"
    },
    "pager": {
        "size": 10,
        "number": 1,
        "pageCount": 12,
        "itemCount": 120
    }
}