import React, { useEffect, useState } from 'react';
import okAnalyticsService from "../../../../services/okAnalytics";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterDateCompare } from "../../../common/Filter";
import './DataCompare.scss';
import { AD_PLATFORM } from "../../../../models/ad-platform";
import ExportReport from "../ExportReport";

const DataCompare = ({ analyticsStore, userStore, uiStore }) => {
    const [loading, setLoading] = useState(true);
    const [adPlatform, setAdPlatform] = useState(AD_PLATFORM);
    const [total, setTotal] = useState([]);
    const [items, setItems] = useState([]);

    const { filterDateCompare } = analyticsStore;

    const [sourcesList, setSourcesList] = useState([]);

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_data-compare',
            target: 'page',
            event: 'open',
        });
    }, []);


    useEffect(() => {
        setLoading(true);

        analyticsStore.getDataCompare()
            .then((result) => {
                if (result.total) {
                    setTotal(result.total);
                    getSourcesList(result.total)
                }
                if (result.items) {
                    setItems(result.items)
                }
                setLoading(false)
            })
        .catch(e => {
            console.log('error', e)
        })

        setAdPlatform(() => {
            return AD_PLATFORM.filter(item => item.value !== 'none')
        })

    }, [
        JSON.stringify(filterDateCompare.date),
        JSON.stringify(filterDateCompare.branches),
        JSON.stringify(filterDateCompare.sources)
    ]);

    const handlerGetReport = (event) => {
        if (event === 'GET_REPORT') {
            analyticsStore.downloadXlsReportDataComparison()
                .then(_ => {
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: '',
                            detail: 'Отчет будет отправлен на почту ' + userStore.profile.user.email,
                            sticky: false,
                        }
                    );
                })
                .catch(e => e)
        }
    }

    const getSourcesList = (sourcesObject) => {
        let sList = [];
        for (const key in sourcesObject) {
            sList.push(key)
        }

        setSourcesList(sList)
    }

    const checkSourceInList = (source) => {
        return sourcesList.includes(source)
    }

    return (<>
        <div className={cn('page__analytics', 'page')}>
            <div className={cn('title')}>
                <h1>Аналитика</h1>
                <ExportReport
                    isMobile={false}
                    userStore={userStore}
                    handlerGetReport={handlerGetReport}
                />

            </div>
            <div className={cn('analytics-tabs')}>
                <Tabs/>
            </div>
            <div className={cn('analytics-filter')}>
                <FilterDateCompare loading={loading}/>
            </div>
            <div className={cn('analytics-table', 'table', 'data-compare-table')}>
                <div className={cn('table__wrapper')}>
                    {loading && <div className={'loader'}/>}
                    {sourcesList.length > 0 && <div className={cn('table')}>
                        <div className={cn('table__header')}>
                            <div className={cn('table__row')} style={{"--my-repeat": Object.keys(total).length}}>
                                <div className={cn('table__cell', 'cell__title')}>Филиал</div>

                                {adPlatform.map((ad, key) => {
                                    if (checkSourceInList(ad.value)) {
                                        return (
                                            <div className={cn('table__cell')} key={key}>
                                                <div className={cn('ad__ico')}>
                                                    <img src={ad.ico} alt={ad.name} width={21} height={21}/>
                                                    <div>{ad.shortName}</div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}

                            </div>
                        </div>
                        <TotalLine total={total} codeBlock={filterDateCompare.openBlock[0].value}/>
                        <div className={cn('table__body')}>
                            {items.length > 0 && items.map((item, key) => {
                                return (
                                    <ItemLine key={key}
                                              item={item}
                                              codeBlock={filterDateCompare.openBlock[0].value}
                                              total={total} />
                                )
                            })}
                        </div>

                    </div>}
                </div>
            </div>
        </div>
    </>)
}

const ItemLine = ({ item, codeBlock, total }) => {
    return (
        <>
            <div className={cn('table__row')} style={{"--my-repeat": Object.keys(total).length}}>
                <div className={cn('table__cell', 'cell', 'cell__title')}>
                    <div className={cn('ico', 'ico__bag')}></div>
                    <div title={item.branch.title}>
                        <TitleBranch branchTitle={item.branch.title}/>
                    </div>
                </div>
                {total['avito'] && <div className={cn('table__cell')}>
                    {item.sources['avito'] ? <SetValueString data={item.sources['avito']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['google'] && <div className={cn('table__cell')}>
                    {item.sources['google'] ? <SetValueString data={item.sources['google']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['yandex'] && <div className={cn('table__cell')}>
                    {item.sources['yandex'] ? <SetValueString data={item.sources['yandex']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['2gis'] && <div className={cn('table__cell')}>
                    {item.sources['2gis'] ? <SetValueString data={item.sources['2gis']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['otzovik'] && <div className={cn('table__cell')}>
                    {item.sources['otzovik'] ? <SetValueString data={item.sources['otzovik']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['flamp'] && <div className={cn('table__cell')}>
                    {item.sources['flamp'] ? <SetValueString data={item.sources['flamp']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['irecommend'] && <div className={cn('table__cell')}>
                    {item.sources['irecommend'] ?
                        <SetValueString data={item.sources['irecommend']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['zoon'] && <div className={cn('table__cell')}>
                    {item.sources['zoon'] ? <SetValueString data={item.sources['zoon']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['vkontakte'] && <div className={cn('table__cell')}>
                    {item.sources['vkontakte'] ? <SetValueString data={item.sources['vkontakte']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['prodoctorov'] && <div className={cn('table__cell')}>
                    {item.sources['prodoctorov'] ?
                        <SetValueString data={item.sources['prodoctorov']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['docdoc'] && <div className={cn('table__cell')}>
                    {item.sources['docdoc'] ? <SetValueString data={item.sources['docdoc']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['yell'] && <div className={cn('table__cell')}>
                    {item.sources['yell'] ? <SetValueString data={item.sources['yell']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
                {total['napopravku'] && <div className={cn('table__cell')}>
                    {item.sources['napopravku'] ?
                        <SetValueString data={item.sources['napopravku']} codeBlock={codeBlock}/> :
                        <span className={cn('diff', 'diff__gray')}>-</span>}
                </div>}
            </div>
        </>
    )
}

const TotalLine = ({ total, codeBlock }) => {
    const titles = {
        'rating': 'Средний рейтинг',
        'scores': 'Общее кол-во оценок',
        'reviews': 'Общее кол-во отзывов',
        'answersResponse': 'Охват официальных ответов',
        'avgTimeReply': 'Средняя скорость оф. ответов'
    }

    return (
        <div className={cn('table__row', 'row')} style={{"--my-repeat": Object.keys(total).length}}>
            <div className={cn('table__cell', 'cell', 'cell__title')}>
                <div className={cn('ico', 'ico__bag')}></div>
                <div>{titles[codeBlock]} {total.length}</div>
            </div>
            {total['avito'] && <div className={cn('table__cell')}>
                <SetValueString data={total['avito']} codeBlock={codeBlock}/>
            </div>}
            {total['google'] && <div className={cn('table__cell')}>
                <SetValueString data={total['google']} codeBlock={codeBlock}/>
            </div>}
            {total['yandex'] && <div className={cn('table__cell')}>
                <SetValueString data={total['yandex']} codeBlock={codeBlock}/>
            </div>}
            {total['2gis'] && <div className={cn('table__cell')}>
                <SetValueString data={total['2gis']} codeBlock={codeBlock}/>
            </div>}
            {total['otzovik'] && <div className={cn('table__cell')}>
                <SetValueString data={total['otzovik']} codeBlock={codeBlock}/>
            </div>}
            {total['flamp'] && <div className={cn('table__cell')}>
                <SetValueString data={total['flamp']} codeBlock={codeBlock}/>
            </div>}
            {total['irecommend'] && <div className={cn('table__cell')}>
                <SetValueString data={total['irecommend']} codeBlock={codeBlock}/>
            </div>}
            {total['zoon'] && <div className={cn('table__cell')}>
                <SetValueString data={total['zoon']} codeBlock={codeBlock}/>
            </div>}
            {total['vkontakte'] && <div className={cn('table__cell')}>
                <SetValueString data={total['vkontakte']} codeBlock={codeBlock}/>
            </div>}
            {total['prodoctorov'] && <div className={cn('table__cell')}>
                <SetValueString data={total['prodoctorov']} codeBlock={codeBlock}/>
            </div>}
            {total['docdoc'] && <div className={cn('table__cell')}>
                <SetValueString data={total['docdoc']} codeBlock={codeBlock}/>
            </div>}
            {total['yell'] && <div className={cn('table__cell')}>
                <SetValueString data={total['yell']} codeBlock={codeBlock}/>
            </div>}
            {total['napopravku'] && <div className={cn('table__cell')}>
                <SetValueString data={total['napopravku']} codeBlock={codeBlock}/>
            </div>}
        </div>
    )
}

const TitleBranch = ({ branchTitle }) => {

    const [title, title2] = branchTitle.split(' - ');

    return (
        <>
            <div>{title}</div>
            {title2 && <>
                <div>{title2}</div>
            </>}
        </>
    )
}

const SetValueString = ({ data, codeBlock }) => {

    let mainValue;

    if (codeBlock === 'avgTimeReply') {

        mainValue = Math.round(data.period.avgTimeReply / 60 / 60)

        if(mainValue <= 48 && mainValue !== 0) {
            mainValue = <span className={cn('diff', 'diff__up', 'top0')}>{mainValue} ч</span>
        }
        else if (mainValue === 0) {
            mainValue = <span className={cn('diff', 'diff__gray', 'top0')}>{mainValue} ч</span>

        }
        else {
            mainValue = <span className={cn('diff', 'diff__down', 'top0')}>{mainValue} ч</span>
        }

    } else if (codeBlock === 'answersResponse') {
        if (data.period.reviews > 0) {
            mainValue = Math.round(data.period.responded / data.period.reviews * 100).toLocaleString('ru-RU') + ' %';
        } else {
            mainValue = '0 %'
        }

    } else {
        mainValue = data.close[codeBlock].toLocaleString('ru-RU')
    }


    const setDiffBlock = (value) => {
        if (codeBlock === 'avgTimeReply') {
            return '';
        } else if (codeBlock === 'answersResponse') {
            if (data.period.reviews > 0) {
                value = Math.round(data.period.responded / data.period.reviews * 100);

                if(value === 0) {
                    return <span className={cn('diff', 'diff__gray', 'top0')}>{value} %</span>
                }
                else if (value >= 75) {
                    return <span className={cn('diff', 'diff__up', 'top0')}>{value} %</span>
                }
                else {
                    return <span className={cn('diff', 'diff__down', 'top0')}>{value} %</span>
                }

            } else {
                return <span className={cn('diff', 'diff__gray', 'top0')}> - </span>
            }
        } else {
            if (value < 0) {
                return <span className={cn('diff', 'diff__down')}>{value}</span>
            } else if (value === 0) {
                return <span className={cn('diff', 'diff__gray')}>{value}</span>
            } else {
                return <span className={cn('diff', 'diff__up')}>+{value}</span>
            }
        }
    }

    return (
        <>
            {codeBlock !== 'answersResponse' && <span className={cn('diff', 'diff__main')}>{mainValue}</span>}
            {setDiffBlock(data.diff[codeBlock])}
        </>
    )
}

export default inject('analyticsStore', 'userStore', 'uiStore')(observer(DataCompare));