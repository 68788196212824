import React, { useEffect, useState } from "react";
import okAnalyticsService from "../../../../services/okAnalytics";
import { isMobile } from "react-device-detect";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterQr } from "../../../common/Filter";
import { inject, observer } from "mobx-react";
import { getDetailDataByPlatform } from "../../../../helpers";
import ColoringText from "../applications/ColoringText";
import { AD_PLATFORM_TYPE } from "../../../../models/ad-platform";
import ExportReport from "../ExportReport";

const QRCodeCommon = () => {
    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_qrcode',
            target: 'page',
            event: 'open',
        });
    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <QRCodeDesktop/> : <QRCodeDesktop/>}
        </>
    );

}
const QRCodeDesktop = inject('analyticsStore', 'userStore', 'uiStore')(observer(
    ({ analyticsStore, userStore, uiStore }) => {
        const [items, setItems] = useState([]);
        const [loading, setLoading] = useState(false);
        const { filterQr } = analyticsStore;

        useEffect(() => {
            setLoading(true);
            analyticsStore.getQrList()
                .then(result => {
                    setItems(result);
                    setLoading(false);
                });
        }, [JSON.stringify(filterQr)]);

        const handlerGetReport = (event) => {
            if (event === 'GET_REPORT') {
                analyticsStore.downloadXlsReportQr()
                    .then(_ => {
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Отчет будет отправлен на почту '+ userStore.profile.user.email,
                                sticky: false,
                            }
                        );
                    })
                    .catch(e => e)
            }
        }


        return (
            <>
                <div className={cn('page__analytics', 'page', 'qrcode-table')}>
                    <div className={cn('title')}>
                        <h1>Аналитика</h1>
                        <ExportReport
                            isMobile={false}
                            userStore={userStore}
                            handlerGetReport={handlerGetReport}
                        />

                    </div>
                    <div className={cn('analytics-tabs')}>
                        <Tabs/>
                    </div>
                    <div className={cn('analytics-filter')}>
                        <FilterQr loading={loading}/>
                    </div>
                    <div className={cn('analytics-table', 'table')}>
                        <div className={cn('table__wrapper')}>
                            {loading && <div className={'loader'}/>}
                            <div className="p-datatable p-component wide">
                                <div className="p-datatable-wrapper">
                                    <table role="grid" className={'qr-table'}>
                                        <thead className="p-datatable-thead">
                                        <tr>
                                            <th className={'branch'}>Филиал</th>
                                            <th className={cn('source', 'align__right')}>Всего<br/>переходов
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('avito').ico} width={26} height={26}
                                                     alt='Avito'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('google').ico} width={26} height={26}
                                                     alt='Google'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('yandex').ico} width={26} height={26}
                                                     alt='Яндекс.Карты'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('2gis').ico} width={26} height={26}
                                                     alt='2ГИС'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('otzovik').ico} width={26} height={26}
                                                     alt='Отзовик'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('flamp').ico} width={26} height={26}
                                                     alt='Flamp'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('irecommend').ico} width={26} height={26}
                                                     alt='Irecommend'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('zoon').ico} width={26} height={26}
                                                     alt='Zoon'/>
                                            </th>
                                            <th className={cn('source-img', 'align__right')}>
                                                <img src={getDetailDataByPlatform('vkontakte').ico} width={26} height={26}
                                                     alt='Вконтакте'/>
                                            </th>
                                            <th className={cn('negative', 'align__right')}>Перехвачено<br/>нег. отзывов
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items?.total && <FirstQrCodeRow
                                            data={items?.total}
                                        />}
                                        {items.items?.length > 0 && items.items.map((item, key) => (
                                            <QrCodeRow data={item} key={key + '_' + item.id}/>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
))

const FirstQrCodeRow = ({ data }) => {

    const [total, setTotal] = useState(0);
    const [platform, setPlatform] = useState({});

    useEffect(() => {
        let tmpPlatform = {};
        let countTotal = 0;

        data.transitions.map(item => {
            countTotal = countTotal + item.value;
            tmpPlatform[item.code] = item.value;
        });

        setTotal(countTotal)
        setPlatform(tmpPlatform)
    }, [data])

    return (
        <>
            <tr className={cn('first-row')}>
                <td>
                    <div className={cn('name')}>
                        <div className={cn('ico', 'ico__bag')} />
                        <div>Все филиалы</div>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={total}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={cn('number')}>
                        <ColoringText value={platform.avito}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.google}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.yandex}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform[AD_PLATFORM_TYPE.DGIS]}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.otzovik}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.flamp}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.irecommend}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.zoon}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.vkontakte}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={data.claims}/>
                    </div>
                </td>

            </tr>
        </>
    );
};

const QrCodeRow = ({ data }) => {
    const [title, title2] = data.branch.title.split(' - ');
    const [total, setTotal] = useState(0);
    const [platform, setPlatform] = useState({});

    useEffect(() => {
        let tmpPlatform = {};
        let countTotal = 0;

        data.transitions.map(item => {
            countTotal = countTotal + item.value;
            tmpPlatform[item.code] = item.value;
        });

        setTotal(countTotal)
        setPlatform(tmpPlatform)
    }, [data])

    return (
        <>
            <tr>
                <td>
                    <div className={cn('name')}>
                        <div className={cn('ico', 'ico__bag')}/>
                        <div title={data.branch.title}>
                            {title} {!!title2 > 0 && <>-<br/>{title2}</>}
                        </div>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={total}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={cn('number')}>
                        <ColoringText value={platform.avito}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.google}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.yandex}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform[AD_PLATFORM_TYPE.DGIS]}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.otzovik}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.flamp}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.irecommend}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.zoon}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={platform.vkontakte}/>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <div className={'number'}>
                        <ColoringText value={data.claims}/>
                    </div>
                </td>
            </tr>
        </>
    )


}

export default QRCodeCommon;
