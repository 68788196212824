import React, { useEffect, useState } from "react";
import cn from "classnames";
import check_ico from '../../../../images/check_ico.png';
import { getDetailDataByPlatform } from "../../../../helpers";
import { inject, observer } from "mobx-react";
import {Paginator} from "primereact/paginator";
import { FilterCompanyList } from "../../../common/Filter";
import StatusBadge from "../edit/bloks/StatusBadge";
import "../CompanyList.scss";
import PagerSizeSelector from "../../review/list/common/PagerSizeSelector";
import { reaction } from "mobx";
import { scroller } from "react-scroll";
import { TextToolTip } from "../../../common/ToolTip";

const CompanyList = ({ geoStore, geoDataStore, uiStore }) => {

    const [companyList, setCompanyList] = useState([]);

    const { filter, setPager, pager } = geoDataStore;
    const [loading, setLoading] = useState(true);
    const [first, setFirst] = useState(pager.number);
    const [rows, setRows] = useState(pager.size);

    const onBasicPageChange = (event) => {
        const page = event.page+1
        setPager({"number": page})
        setFirst(event.first)
        setRows(event.rows)
    }

    const setCountPagerSize = (count) => {
        setPager({"number": 0, "size": count})
        setFirst(1)
    }

    useEffect(() => {
        reaction(
            () => JSON.stringify(geoDataStore.filter),
            (result) => {
                //console.log('filter', result);
            }
        );

        scroller.scrollTo('root', {
            offset: 0,
            duration: 0,
            delay: 0,
            smooth: 'easeInOutQuart'
        });

    }, [])

    useEffect(() => {
        geoDataStore.getCompanyList().then(result => {
            if(result) {
                setCompanyList(result.items);
            }
            setLoading(false)
        })
            .catch(e => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: 'Ошибка',
                        detail: e.error.description,
                    }
                );
            })

    }, [JSON.stringify(filter)]);

    return (
        <>
            {companyList.length !== 0 && <div className={cn('page__company-list', 'page')}>
                <div className={'company-list'}>
                    <div className={cn('title')}>
                        <h1>Мои компании</h1>
                    </div>
                    <div className={cn('company-filter')}>
                        <FilterCompanyList loading={loading}/>
                        <div className={cn('list-filter', 'list-filter__pager-size')}>
                            <PagerSizeSelector
                                setCount={(size) => {
                                    return setCountPagerSize(size)
                                }}
                                value={filter.page.size}
                                options={[
                                    10, 30, 50, 100
                                ]}
                            />
                        </div>
                    </div>
                    <div className={cn('company-table', 'table')}>
                        <div className={cn('table__wrapper')}>

                            <div className="p-datatable p-component">
                                <div className="p-datatable-wrapper">
                                    <table role="grid">
                                        <thead className="p-datatable-thead">
                                        <tr>
                                            <th>Филиал</th>
                                            <th className={cn('status-branch-col')}>Статус филиала</th>
                                            <th>Адрес</th>
                                            <th>Заполненность</th>
                                            <th className={cn('platform-col')}>Площадка</th>
                                            <th className={cn('actuality-col')}>Актуальность</th>
                                            <th className={cn('status-col')}>Статус</th>
                                        </tr>
                                        </thead>
                                        <tbody className={cn('p-datatable-tbody')}>
                                        {companyList.map((company, key)=>{
                                            return <Row item={company} key={key}/>
                                        })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {pager.pageCount > 0 && <div className={cn('paginator-container')}>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={pager.itemCount}
                        onPageChange={onBasicPageChange}
                    />
                </div>}

            </div>}
        </>
    )
}

const Row = ({item}) => {

    let countColor = Math.round(item.completeness/10)

    const handleClick = (e) => {
        if(!window.getSelection().toString().length) {
            e.preventDefault()
            e.stopPropagation();

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = `/company/${item.id}`;
            a.target = "_blank";
            a.click();
            document.body.removeChild(a);
        }
    }

    return (<tr className="p-datatable-row" onClick={handleClick}>
        <td className={cn('branch')}>
            <div>
                {item.title}
            </div>
        </td>
        <td>
            <StatusBadge code={item.type.code}>{item.type.title}</StatusBadge>
            <StatusBadge code={item.status.code}>{item.status.title}</StatusBadge>
            {item.isNew && <StatusBadge code={'new'}>Новая</StatusBadge>}
        </td>
        <td>
            {item.address.city}, <br/>{item.address.street} {item.address.building}
        </td>
        <td className={cn('completeness')}>
            <div className={cn('progress')}>
                <ProgressUl count={countColor}/>
            </div>
        </td>
        <td className={cn('platform')}>
            {item.adplatforms.length > 0 && <ul>
                {
                    item.adplatforms.map((platform, key) => {
                        const { ico } = getDetailDataByPlatform(platform.type);
                        return (
                            <li key={key}>
                                <img src={ico} width={18} height={18} alt={platform.rating}/>
                                <span className={cn('rating')}>
                                    <span className="pi-star pi"></span>
                                    <span className="value">{platform.rating}</span>
                                </span>
                            </li>
                        )
                    })
                }
            </ul>}
        </td>
        <td style={{'overflow': 'visible'}}>
            {item.adplatforms.length > 0 && <ul className={'align-center'}>
                { item.adplatforms.map((platform, key) => {
                        return (
                            <li key={key}>
                                <Actuality state={platform.source.state.code} difference={platform.difference.count} />
                            </li>
                        )
                    })}
            </ul>}
        </td>
        <td>
            {item.adplatforms.length > 0 && <ul className={'align-center'}>
                {
                    item.adplatforms.map((platform, key) => {
                        return (
                            <li key={key}>
                                {platform.type === 'yandex' &&
                                    <span className={cn('check')}>
                                        <img src={check_ico} width={18} height={18}/>
                                    </span>
                                }
                            </li>
                        )
                    })
                }
            </ul>}
        </td>
    </tr>)}

const Actuality = ({state, difference}) => {
    const [className, setClassName] = useState('');
    const [content, setContent] = useState('content');

    useEffect(() => {
// черновик для попапов с сообщениями о статусе
        if(state !== 'enabled') {
            setContent('Ожидаем авторизации')
            setClassName('pause')
        }
        else {
            if(difference !== 0) {
                setContent('Имеются расхождения')
                setClassName('error')
            }
            else {
                setContent('Информация актуальна')
                setClassName('check')
            }
        }
    }, [])

    return (
        <>
            <div className={cn('actuality', `actuality__${className}`)} >
                <TextToolTip content={content}>
                    <span></span>
                </TextToolTip>
                {(difference !== 0 && state === 'enabled') && <span className={'count'}>{difference}</span>}
            </div>
        </>
    )
}



const ProgressUl = ({count}) => {

    return (
        <ul className={cn('scale')}>
            {[1,2,3,4,5,6,7,8,9,10].map((item, key) => {
                if(item<=count) {
                    return (<li className={cn({'red': count<=5}, {'green': count > 5})} key={key}></li>)
                }
                else {
                    return (<li key={key}></li>)
                }
            })}
        </ul>

    )
}

export default inject('geoStore', 'geoDataStore', 'uiStore')(observer(CompanyList));