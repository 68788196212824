import React, { useEffect, useState } from "react";
import cn from "classnames";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Map, Placemark, YMaps, ZoomControl } from "react-yandex-maps";
import { inject, observer } from "mobx-react";
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import "../AddressSuggest.scss";

const AddressCompany = ({ geoStore, uiStore }) => {

    const { form } = geoStore;
    const [geometry, setGeometry] = useState([]);
    const [mapEnabled, setMapEnabled] = useState(false);

    useEffect(() => {
        setGeometry([form.latitude.value, form.longitude.value])
    }, [])

    const clickOnMap = (e) => {
        const coords = e.get('coords')

        geoStore.getAddress({ lat: coords[0], lon: coords[1] })
            .then(result => {
                if (result === 'error') {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: 'Ошибка определения адреса',
                            sticky: false,
                            life: 2000,

                        }
                    );
                }
            })

        geoStore.setFormFieldValue('latitude', coords[0]);
        geoStore.setFormFieldValue('longitude', coords[1]);
        setGeometry(coords)
    }

    const setAddressSuggestions = (suggestions) => {
        if (suggestions && suggestions.data) {
            const address = {
                unrestricted_value: suggestions.unrestricted_value,
                country: suggestions.data.country ?? '',
                region: suggestions.data.region_with_type ?? '',
                city: suggestions.data.city_with_type ? suggestions.data.city_with_type : suggestions.data.settlement_with_type,
                postal_code: suggestions.data.postal_code ?? '',
                street: suggestions.data.street_with_type ?? '',
                house: suggestions.data.house ?? ''
            }

            geoStore.setAddressField(address);
            geoStore.setFormFieldValue('latitude', suggestions.data.geo_lat);
            geoStore.setFormFieldValue('longitude', suggestions.data.geo_lon);

            setGeometry([suggestions.data.geo_lat, suggestions.data.geo_lon])
        }
    }

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Адрес</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <AddressSuggest style={{ width: '580px' }} handleChange={setAddressSuggestions}/>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('textarea-field')}>
                            <div className={cn("p-float-label")}>
                                <InputTextarea
                                    id="addressComment"
                                    name="addressComment"
                                    required={form.addressComment.required}
                                    value={form.addressComment.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('addressComment', e.target.value)
                                    }}
                                />
                                <label htmlFor='addressComment' className={cn('active')}>Комментарий к адресу...</label>
                            </div>
                        </div>
                    </div>

                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                {form.address.value.country}
                                <label htmlFor='country' className={cn('active')}>Страна</label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                {form.address.value.region}
                                <label htmlFor='region' className={cn('active')}>Область</label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                {form.address.value.city}
                                <label htmlFor='city' className={cn('active')}>Город / н.п.</label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                {form.address.value.postcode}
                                <label htmlFor='postal_code' className={cn('active')}>Почтовый индекс</label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                {form.address.value.street}
                                <label htmlFor='street' className={cn('active')}>Улица</label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                {form.address.value.building}
                                 <label htmlFor='house' className={cn('active')}>Номер дома</label>
                            </div>
                        </div>

                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn("input-switch")}>
                            <InputSwitch
                                checked={mapEnabled}
                                onChange={() => {
                                    setMapEnabled(!mapEnabled)
                                }}
                            />
                        </div>
                        <div
                            className={cn("input-switch-label")}
                            onClick={() => {
                                setMapEnabled(prev => !prev)
                            }}
                        >
                            Задать точные координаты на карте вручную
                        </div>
                    </div>
                    {mapEnabled && <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <InputText
                                    id='latitude'
                                    type="text"
                                    name='latitude'
                                    value={form.latitude.value}
                                    readOnly
                                />
                                <label htmlFor='latitude'>Широта</label>
                            </div>
                        </div>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <InputText
                                    id='longitude'
                                    type="text"
                                    name='longitude'
                                    value={form.longitude.value}
                                    readOnly
                                />
                                <label htmlFor='longitude'>Долгота</label>
                            </div>
                        </div>
                    </div>}
                    <div className={cn("form__row", "ya__map")}>
                        {!mapEnabled && <div className={cn("disabled")}></div>}
                        <YMaps>
                            <Map
                                className={cn("map-container")}
                                //defaultState={{center: [55.75, 37.57], zoom: 11}}
                                defaultState={{ center: geometry, zoom: 16, controls: [], }}
                                state={{ center: geometry, zoom: 16 }}
                                onClick={(e) => clickOnMap(e)}
                            >
                                <ZoomControl options={
                                    {
                                        position: {
                                            top: 50,
                                            right: 20,
                                        }
                                    }
                                } />
                                <Placemark
                                    defaultGeometry={[55.75, 37.57]}
                                    geometry={geometry}
                                />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        </>
    )
}

const AddressSuggest = inject('geoStore')(observer(({ geoStore, style, handleChange }) => {
        const [value, setValue] = useState();

        useEffect(() => {
            if (value !== undefined) {
                handleChange(value)
            }
        }, [value]);

        return (
            <div className={cn("p-float-label")} style={style}>
                <AddressSuggestions
                    token={process.env.REACT_APP_DADATA_TOKEN}
                    style={{ width: '100%' }}
                    value={value}
                    onChange={setValue}
                    id="address-suggest"
                />
                <label htmlFor='address-suggest' className={cn('active')}>Адрес</label>
            </div>
        )
    }
))

export default inject('geoStore', 'uiStore')(observer(AddressCompany))