import {action, observable, toJS} from "mobx";
import geoDataService from "../../services/geo/geo-category";

class GeoDiffStore {

    @observable mainData = [
        {"code": "title"},
        {"code": "short_title"},
        {"code": "status_branch"},
        {"code": "ok_link"},
        {"code": "tg_link"},
        {"code": "twitter_link"},
        {"code": "viber_phone"},
        {"code": "vk_url"},
        {"code": "whatsapp_phone"},
        {"code": "youtube_link"},
        {"code": "address"},
        {"code": "category"},
        {"code": "phones"},
        {"code": "emails"},
        {"code": "websites"},
        {"code": "work-time"},
        {"code": "coordinates"},
    ];
    @observable adData = [];

    constructor() {

    }

    getMainData(id) {
        return geoDataService.getCompanyData(id).then(result => {
            this.setMainData(result)
            return result
        })
    }

    @action setMainData(data = []) {
        let copy = []
        this.mainData.map(md=> {
            copy.push(data.items.find(item => item.code === md.code) ?? {code: md.code})
        })

        this.mainData = copy
    }

    @action setAdData(data = []) {
        this.adData = data
    }

    @action setDiffData(data) {
        this.diffData = data;
    }

    mergePlatformData(adData, platformData, source) {

        let diffData = {};

        if(!!this.mainData.length ) {
            this.mainData.map((item) => {
                const masterValue = this.getValueFromData(item, source)

                const platformValue = platformData.find((pd)=> {
                    return pd.code === `${item.code}`
                })

                const adValue = adData.find((ad)=> {
                    return ad.code === `${item.code}@${source}`
                })

                diffData[item.code] = {
                    main: toJS(masterValue) ?? null,
                    patch: adValue ?? null,
                    platform: platformValue ?? null
                };


            })

            return diffData
        }
        else return []

    }

    mergeAdDataAndMainData(adData, source) {

        let diffData = {};

        if(!!this.mainData.length ) {
             this.mainData.map((item) => {
                 const masterValue = this.getValueFromData(item, source)
                 const adValue = adData.find((ad)=> {
                     return ad.code === `${item.code}@${source}`
                 })

                 diffData[item.code] = {
                     main: toJS(masterValue) ?? null,
                     patch: adValue ?? null
                 };

            })
            return diffData
        }
        else return []
    }

    getValueFromData(item, source) {
        if(!!item.override?.length){
            const element = item.override.find(el => {
                return el.source === source
            })

            if(element) {
                return element.value;
            }
            else {
                return item.value
            }
        }
        else {
            return item.value
        }
    }
}

const geoDiffStore = new GeoDiffStore()

export default geoDiffStore;