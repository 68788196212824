import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import NameCompany from "./bloks/NameCompany";
import { ContactInfo } from "./bloks/ContactInfo";
import { PhoneInfo } from "./bloks/PhoneInfo";
import AddressCompany from "./bloks/AddressCompany";
import { SitesInfo } from "./bloks/SitesInfo";
import WorkTime from "./bloks/WorkTime";
import DataSynchronizationInfo from "./bloks/DataSynchronizationInfo";
import FloatMenu from "./bloks/FloatMenu";
import NavigateBtnUp from "components/pages/application/common/NavigateBtnUp";
import { Button } from "primereact/button";
import { scroller } from "react-scroll";
import Category from "./bloks/Category";
import { reaction, toJS } from "mobx";
import { useParams } from "react-router";
import MessengerInfo from "./bloks/MessengerInfo";
import SocialMediaInfo from "./bloks/SocialMediaInfo";
import StatusBranch from "./bloks/StatusBranch";
import StatusBadge from "./bloks/StatusBadge";
import moment from "moment/moment";
import { Link, Prompt } from "react-router-dom";
import "../Company.scss";
import "./Company.scss";

const Company = ({ geoStore, uiStore, geoDataStore, geoDiffStore }) => {

    const { form, notChangeDataModel, getStatusUpdate, etag, setEtag } = geoStore;
    const { id } = useParams();

    const [showFloatMenu, setShowFloatMenu] = useState(false)
    const [showButtonUp, setShowButtonUp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [companyInfo, setCompanyInfo] = useState(null)
    const [saved, setSaved] = useState(false)
    //const [etag, setEtag] = useState(null)

    const checkRequiredFields = () => {
        const fieldsError = [
            ...geoStore.checkPrimaryFields(),
            ...geoStore.checkArrayField(),
            ...geoStore.checkSetupFields(),
        ]

//todo переписать на ref
        if (fieldsError.length > 0) {
            fieldsError.map(field => {
                const element = document.getElementById(field);
                if (element) {
                    element.classList.add('p-invalid')
                } else {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: 'Невозможно сохранить данные. Выберите как минимум основные категории для площадок.',
                            sticky: true,
                        }
                    );

                }
            })

            const element = document.getElementsByClassName('p-invalid')

            if (element[0]) {
                scroller.scrollTo(element[0].id, {
                    offset: -180,
                    duration: 500,
                    delay: 0,
                    smooth: 'easeInOutQuart'
                });
            }
        } else {
            geoStore.saveCompanyData(id).then(result => {
                switch (result) {
                    case 'OK':
                        setSaved(true);
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: 'Форма сохранена успешно',
                                detail: '',
                                sticky: false,
                                life: 2000,
                            }
                        );

                        //если изменения были запускаем проверку обновления
                        geoStore.setStatusUpdate('process')
                        geoStore.startUpdate(id, etag)

                        break;

                    case 'UNCHANGED':
                        setSaved(true);
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: 'Форма сохранена успешно',
                                detail: '',
                                sticky: false,
                                life: 2000,
                            }
                        );
                        break;

                    default:
                        if (result.error.data?.length > 0) {
                            result.error.data.map(item => {
                                uiStore.setMessage(
                                    {
                                        severity: 'error',
                                        summary: result.error.description,
                                        detail: item.message,
                                        sticky: true,
                                    }
                                )
                            });
                        } else {
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: 'Ошибка',
                                    detail: result.error.description,
                                    sticky: true,
                                }
                            );
                        }
                }
            })
                .catch(error => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: error,
                        }
                    );

                })
        }
    }

    useEffect(() => {
        const showWindow = (event) => {
            if (geoStore.isFormChange()) {
                event.preventDefault();
                event.returnValue = "";
            }
        }

        window.addEventListener('beforeunload', showWindow)
        return () => {
            window.removeEventListener("beforeunload", showWindow);
        }
    }, [])


    useEffect(() => {

        reaction(
            () => JSON.stringify(geoStore.companyInfo),
            (result) => {
                setCompanyInfo(JSON.parse(result))
                geoStore.getCompanyData(id).then(result => {
                    geoDiffStore.setMainData(result)
                })
            }
        );

        reaction(
            () => JSON.stringify(form),
            (result) => {
                //console.log('reaction main', toJS(form.workTime));
            }
        );

        reaction(
            () => JSON.stringify(geoDiffStore.mainData),
            (result) => {
                //console.log('mainData', JSON.parse(result));
            }
        );

        reaction(
            () => JSON.stringify(geoDiffStore.adData),
            (result) => {
                //console.log('adData', JSON.parse(result));
            }
        );

        id && geoStore.getCompanyInfo(id).then(({result, headers}) => {
            if(!!headers['etag']) {
                setEtag(headers['etag'])
            }
            setCompanyInfo(result)
            return geoStore.getCompanyData(id)
        }).then(result => {
            geoDiffStore.setMainData(result)
            setLoading(true)
        })
            .catch(error => {
                console.error('error', error)
            })

        const scrollListener = () => {
            const scrollTop = (window.pageYOffset || document.documentElement.scrollTop);

            if (scrollTop >= 100) {
                setShowFloatMenu(true);
                setShowButtonUp(true);
            } else {
                setShowFloatMenu(false);
                setShowButtonUp(false);
            }
        }

        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        }
    }, [])

    useEffect(()=>{
       // console.log('etag', etag)
        //console.log('setCompanyInfo', companyInfo)
    }, [companyInfo])

    return (

        <>
            <div className={cn('page__company-edit', 'page')}>
                <Prompt
                    when={geoStore.isFormChange()}
                    message={(location) => {
                        return !!location.hash
                            ? true
                            : "Внимание! Если Вы закроете эту страницу, то введенные данные не сохранятся"
                    }}
                />

                <div className={'company-edit'}>
                    {showFloatMenu && <FloatMenu checkRequiredFields={checkRequiredFields}/>}
                    {companyInfo && <div className={cn('company-info')}>
                        <div className={cn('company-info__block', 'flex')}>
                            <div className={cn('img')}></div>
                            <div>
                                <div className={cn('company-info__name')}>{companyInfo.title}</div>
                                <div
                                    className={cn('company-info__address')}>{companyInfo.address.city}, {companyInfo.address.street}, {companyInfo.address.building}</div>
                            </div>
                        </div>
                        <div className={cn('company-info__block')}>
                            <StatusBadge code={companyInfo.status.code}>{companyInfo.status.title}</StatusBadge>
                        </div>
                        <div className={cn('company-info__block')}>
                            <div className={cn('company-info__date-text')}>Дата создания:</div>
                            <div
                                className={cn('company-info__date')}>{moment(companyInfo.createdAt).format("hh:mm, DD MMMM YYYY")}</div>
                        </div>
                        <div className={cn('company-info__block')}>
                            <div className={cn('company-info__date-text')}>Последнее редактирование</div>
                            <div className={cn('company-info__date')}>
                                {geoStore.getStatusUpdate() === 'done' ? moment(companyInfo.editedAt).format("hh:mm, DD MMMM YYYY") : 'Обновление'}
                            </div>
                        </div>

                    </div>}
                    <div className={cn('title')}>
                        <h1>Редактирование компаний ({getStatusUpdate()} для отладки)</h1>
                    </div>
                    {loading && <div className={cn('company-form__blocks')}>
                        {companyInfo && <div className={cn('block__wrapper')} id={'DataSynchronizationInfo'}>
                            <DataSynchronizationInfo companyId={id} companyInfo={companyInfo}/>
                        </div>}

                        <div className={cn('block__wrapper')} id={'Status'}>
                            <StatusBranch/>
                        </div>
                        {/*Название*/}
                        <div className={cn('block__wrapper')} id={'NameCompany'}>
                            <NameCompany/>
                        </div>

                        {/*Бренд и код филиала*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'BrandBranch'} style={{ 'opacity': '0.2' }}>
                            <BrandBranch/>
                        </div>
*/}
                        {/*Адрес*/}
                        <div className={cn('block__wrapper')} id={'AddressCompany'}>
                            <AddressCompany/>
                        </div>

                        {/*Юридическая информация*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'LegalInfo'} style={{ 'opacity': '0.2' }}>
                            <LegalInfo/>
                        </div>
*/}
                        {/*Категории*/}
                        <div className={cn('block__wrapper')} id={'Category'}>
                            <Category/>
                        </div>

                        {/*Телефон*/}
                        <div className={cn('block__wrapper')} id={'PhonesInfo'}>
                            <PhoneInfo/>
                        </div>

                        {/*Мессенджеры*/}
                        <div className={cn('block__wrapper')} id={'MessengerInfo'}>
                            <MessengerInfo/>
                        </div>

                        {/*Электронная почта*/}
                        <div className={cn('block__wrapper')} id={'ContactInfo'}>
                            <ContactInfo/>
                        </div>

                        {/*Сайты*/}
                        <div className={cn('block__wrapper')} id={'SitesInfo'}>
                            <SitesInfo/>
                        </div>

                        {/* Социальные сети */}
                        <div className={cn('block__wrapper')} id={'SocialMediaInfo'}>
                            <SocialMediaInfo/>
                        </div>

                        {/*Время работы*/}
                        <div className={cn('block__wrapper')} id={'WorkTime'}>
                            <WorkTime/>
                        </div>

                        {/*Способы оплаты*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'PaymentMethods'} style={{ 'opacity': '0.2' }}>
                            <PaymentMethods/>
                        </div>
                        */}

                        {/*Описание*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'Description'} style={{ 'opacity': '0.2' }}>
                            <Description/>
                        </div>
                        */}

                        {/*Логотип и обложка*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'Logo'} style={{ 'opacity': '0.2' }}>
                            <Logo/>
                        </div>
                        */}

                        <div className={cn('block__wrapper', 'pt-0')}>
                            <div className={cn('block')}>
                                <div className={cn('block__form')}>
                                    <div className={cn("form__row")}>
                                        <Link to='/company-list' className="p-button p-component back-btn">
                                            Отмена
                                        </Link>
                                        &nbsp;
                                        <Button
                                            label='Обновить информацию'
                                            onClick={() => {
                                                checkRequiredFields()
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showButtonUp && <NavigateBtnUp/>}
                    </div>}

                </div>
            </div>
        </>
    )

}

export default inject('geoStore', 'uiStore', 'geoDataStore', 'geoDiffStore')(observer(Company));